import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link
import "./CardItem.css";

function CardItem({ src, text, label, path }) {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <li className="cards__item">
      <Link className="cards__item__link" to={path}>
        <div className="cards__item__pic-wrap" data-category={label}>
          {isLoading && (
            <div className="loader-container">
              <div className="custom-loader"></div>
            </div>
          )}
          <img
            className={`cards__item__img ${isLoading ? "hidden" : "fade-img"}`}
            src={src}
            onLoad={handleImageLoad}
            alt={text}
          />
        </div>
        <div className="cards__item__info">
          <h5 className="cards__item__text">{text}</h5>
        </div>
      </Link>
    </li>
  );
}

export default CardItem;
