import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/v3/Navbar";
import Footer from "./components/footer/Footer";
import ScrollWrapper from "./components/scroll-wrapper/ScrollWrapper";
import Home from "./pages/home/Home";
import References from "./pages/references/References";
import Services from "./pages/services/Services";
import Contact from "./pages/contact/Contact";
import About from "./pages/about/About";
import Projektiranje from "./pages/services/service-pages/projektiranje";
import Nadzor from "./pages/services/service-pages/nadzor";
import Procjena from "./pages/services/service-pages/procjena";
import Etaziranje from "./pages/services/service-pages/etaziranje";
import Legalizacija from "./pages/services/service-pages/legalizacija";

import { I18nextProvider } from "react-i18next";
import i18next from "./components/i18n/i18n";

function App() {
  const path = window.location.hostname === "localhost" ? "" : "http://213.202.100.65/plesk-site-preview/www.nacrtisibenik.hr/http/213.202.100.65";

  return (
    <I18nextProvider i18n={i18next}>
      <div className="overlay">
        <Router>
          <ScrollWrapper>
            <Navbar path={path} />
            <Routes>
              <Route path="/" element={<Home exact path={path} />} />
              <Route path="references" element={<References />} />
              <Route path="services" element={<Services path={path} />}>
                <Route path="projektiranje" element={<Projektiranje path={path} />} />
                <Route path="nadzor" element={<Nadzor path={path} />} />
                {/* <Route path="procjena" element={<Procjena path={path} />} /> */}
                <Route path="etaziranje" element={<Etaziranje path={path} />} />
                <Route path="legalizacija" element={<Legalizacija path={path} />} />
              </Route>
              <Route path="contact" element={<Contact />} />
              <Route path="about" element={<About />} />
            </Routes>
            <Footer path={path} />
          </ScrollWrapper>
        </Router>
      </div>
    </I18nextProvider>
  );
}

export default App;
