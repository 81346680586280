import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import LogoImage from "../footer/images/logo-new-transp.png";

function Footer(props) {
  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <div className="footer-links">
          <div className="footer-link-wrapper">
            <div className="footer-link-items">
              <h2>ADRESA FIRME</h2>
              <div>Put Vida 19</div>
              <div>22000, Šibenik</div>
              <div>Šibensko-kninska županija, Hrvatska</div>
            </div>
            <div className="footer-link-items">
              <h2>PODACI FIRME</h2>
              <div>Vlasnik: Ante Nakić </div>
              <div>Veličina: Mikro poduzetnik </div>
              <div>MB: 05727049</div>
              <div>OIB: 14852789326</div>
            </div>
            <div className="footer-link-items">
              <h2>KONTAKT</h2>
              <div>Ante Nakić tel: +385 95 814 9577</div>
              <div>Karmen Jakus tel: +385 91 507 5903</div>
              <div>Mail: info@nacrtisibenik.hr</div>
            </div>
            <div className="footer-link-items">
              <h2>ČESTA PITANJA</h2>
              <Link to="/services/projektiranje">U svezi projektiranja</Link>
              <Link to="/services/legalizacija">U svezi legalizacije objekata</Link>
              <Link to="/services/etaziranje">U svezi etažiranja</Link>
            </div>
          </div>
        </div>
        <div className="footer-logo">
          <Link to="/" className="footer--logo--link">
            <img src={LogoImage} className="footer--logo-img" alt="Nacrti Šibenik d.o.o" />
          </Link>
        </div>
      </div>
      <div className="impressum-section">
        <div className="website-rights">Nacrti Šibenik d.o.o 2024</div>
      </div>
    </div>
  );
}

export default Footer;
