import React, { useState, useEffect } from "react";

import "./service-pages.css"

import { CiCircleChevRight } from "react-icons/ci";
import {Link} from "react-router-dom"
import { useNavigate } from "react-router-dom"; 


function Legalizacija(props) {

  const navigate = useNavigate(); 


  return (

    
    <div className='services-wrapper'>

  
      <div className='services-main-content--right'>

            <div className='subtitle'>
                O legalizaciji
            </div>
            <div className='subtitle--paragraph'>
                <p> 
                    <span className="subtitle-paragraph-small-title">SAVJETUJEMO VAS.. </span><br/><br/>
                    Nudimo i uslugu savjetovanja ukoliko ne možete
                    putem telefona ili e-maila jasno objasniti situaciju u kojoj se nalazite 
                    kako bi vam složili adekvatnu ponudu, te u tom slučaju dolazimo do vas pogledati 
                    objekt i postojeću situaciju te na licu mjesta savjetujemo koji su sljedeći koraci.
                </p>
                <br/>
                <p>
                    <span className="subtitle-paragraph-small-title">AKO NE ŽIVITE U RH.. </span><br/><br/>
                    Ne živite u Hrvatskoj, a imate bespravno sagrađeni objekt koji bi htjeli 
                    legalizirati na području Šibensko-kninske županije? Rješavanje papirologije 
                    oko ishođenja i podnošenja dokumentacije preuzet ćemo umjesto Vas.
                </p>
                <br/>
                <p>
                    <span className="subtitle-paragraph-small-title">IZRADA DOKUMENTACIJE PREMA VELIČINI ZGRADE</span><br/><br/>
                    <div className='subtitle--paragraph'>
                    <ul className='service--step-list'>
                        <li className='service--step-list--item'>
                            <CiCircleChevRight className='service--step-list--icon'/>
                            <span className="service--step-list--icon--text---standard">Zahtjevna zgrada – iznad 400m² ukupne brutto površine</span>
                        </li>
                        <li className='service--step-list--item'>
                            <CiCircleChevRight className='service--step-list--icon'/>
                            <span className="service--step-list--icon--text---standard">Manje zahtjevna zgrada – do 400m² ukupne brutto površine</span>
                        </li>
                        <li className='service--step-list--item'>
                            <CiCircleChevRight className='service--step-list--icon'/>
                            <span className="service--step-list--icon--text---standard">Jednostavna zgrada – do 100m² ukupne brutto površine</span>
                        </li>
                        <li className='service--step-list--item'>
                            <CiCircleChevRight className='service--step-list--icon'/>
                            <span className="service--step-list--icon--text---standard">Pomoćna zgrada -do 50m² tlocrtne površine pod uvjetom da je u funkciji osnovnog objekta koji je legalan, te da je pomoćni objekt u razini jedne etaže</span>
                        </li>
                    </ul>
            </div>
                </p>
            </div> 
            <div className='subtitle'>
                Legalizacija u koracima
            </div>
            <ul className='service--step-list'>
                <li className='service--step-list--item'>
                    <CiCircleChevRight className='service--step-list--icon'/>
                    <span className="service--step-list--icon--text---standard">Kontaktirati ovlaštenog inženjera građevine, ovlaštenog arhitekta ili geodeta</span>
                </li>
                <li className='service--step-list--item'>
                    <CiCircleChevRight className='service--step-list--icon'/>
                    <span className="service--step-list--icon--text---standard">Izlazak na teren i izmjera</span>
                </li>
                <li className='service--step-list--item'>
                    <CiCircleChevRight className='service--step-list--icon'/>
                    <span className="service--step-list--icon--text---standard">Izrada tehničke dokumentacije – arhitektonske snimke, geodetske snimke</span>
                </li>
                <li className='service--step-list--item'>
                    <CiCircleChevRight className='service--step-list--icon'/>
                    <span className="service--step-list--icon--text---standard">Predaja zahtjeva za izdavanje rješenja o izvedenom stanju u Odjel za graditeljstvo lokalne ili regionalne samouoprave</span>
                </li>
                <li className='service--step-list--item'>
                    <CiCircleChevRight className='service--step-list--icon'/>
                    <span className="service--step-list--icon--text---standard">Izlazak na teren i izmjera</span>
                </li>
                <li className='service--step-list--item'>
                    <CiCircleChevRight className='service--step-list--icon'/>
                    <span className="service--step-list--icon--text---standard">Nadležni referent nakon pregleda projekta i izvršenja provjere na terenu, te nakon što ustanovi da objekt odgovara uvjetima za legalizaciju, šalje projekte za obračun Naknade za nezakonito izgrađenu zgradu (Kazna), te obračun za komunalni i vodni doprinos.</span>
                </li>
                <li className='service--step-list--item'>
                    <CiCircleChevRight className='service--step-list--icon'/>
                    <span className="service--step-list--icon--text---standard">Plaćanje naknade za legalizaciju</span>
                </li>
                <li className='service--step-list--item'>
                    <CiCircleChevRight className='service--step-list--icon'/>
                    <span className="service--step-list--icon--text---standard">Dobivanje rješenja o izvedenom stanju</span>
                </li>
            </ul>


            <div className='subtitle'>
                Troškovi legalizacije
            </div>
            <ul className='service--step-list--contacts'>
                <li className='service--step-list--item'>
                    <div>
                        <span className="service--step-list--contacts---text">Kontaktirajete nas i izračunat ćemo za vas ukupne troškove projektiranja vašeg objekta!</span>
                    </div>
                </li>
                <li className='service--step-list--icon---contact' >
                    <Link to="/contact">        
                        <button 
                            className="service--step-list--icon---contact--btn"
                            onClick={() => navigate('/contact')} 
                        >
                            KONTAKT
                        </button>
                    </Link>
                </li>
            </ul>
            </div>
            

      </div>
 
  
  )
}

export default Legalizacija



